<template>
  <v-dialog v-model="dialog" persistent width="850PX" scrollable :fullscreen="$vuetify.display.mobile">
    <v-card>
      <v-card-title class="pa-4">
        {{ title }}
      </v-card-title>
      <v-divider />
      <v-card-text class="pa-4">
        <v-row>
          <v-col cols="12" md="12">
            <AppTextField v-model="form.name" label="Nome" />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="6">
            <AppTextField v-model="form.phone_wa" label="Whatsapp" />
          </v-col>
          <v-col cols="12" md="6">
            <AppTextField v-model="form.phone" label="Telefone" />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="6">
            <AppCpfField v-model="form.cpf" v-if="!IsJuridic" label="CPF" />
            <AppCnpjField v-model="form.cnpj" v-if="IsJuridic" @company="setCompanyData($event)" show-search
              label="CNPJ" />
          </v-col>
          <v-col cols="12" md="4">
            <v-switch v-model="form.person_type" color="primary" true-value="juridica" false-value="fisica"
              label="É empresa" hide-details="auto" />
          </v-col>
          <!-- <v-col>
            <v-checkbox v-model="form.allow_credit" hide-details="auto" label="Permitir Crediário" />
          </v-col> -->
        </v-row>

        <div class="mt-5 mb-5">
          <v-row class="mb-2">
            <v-col>
              <div class="d-flex align-center" @click="showAddressFields = !showAddressFields" style="cursor: pointer">
                <h5>Endereço (opcional)</h5>
                <template v-if="showAddressFields">
                  <v-icon varia icon="mdi:mdi-chevron-up" />
                </template>
                <template v-else>
                  <v-icon varia icon="mdi:mdi-chevron-down" />
                </template>
              </div>
            </v-col>
          </v-row>

          <div v-if="showAddressFields">
            <v-row class="d-flex align-center">
              <v-col md="4">
                <AppCepField v-model="form.main_address.postal_code" @address="handleCepInput($event)" label="CEP" />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="5">
                <AppTextField v-model="form.main_address.address" label="Endereço" />
              </v-col>
              <v-col md="2">
                <AppTextField v-model="form.main_address.number" label="Número" />
              </v-col>
              <v-col md="5">
                <AppTextField v-model="form.main_address.complement" label="Complemento" />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="5">
                <AppTextField v-model="form.main_address.district" label="Bairro" />
              </v-col>
              <v-col md="5">
                <AppTextField v-model="form.main_address.city" readonly label="Cidade" />
              </v-col>
              <v-col md="2">
                <AppTextField v-model="form.main_address.state" readonly label="Estado" />
              </v-col>
            </v-row>
          </div>
        </div>

        <div class="mb-5">
          <v-row class="mb-2">
            <v-col>
              <div class="d-flex align-center" @click="showAdditionalInfoFields = !showAdditionalInfoFields"
                style="cursor: pointer">
                <h5>Informações adicionais (opcional)</h5>
                <template v-if="showAdditionalInfoFields">
                  <v-icon varia icon="mdi:mdi-chevron-up" />
                </template>
                <template v-else>
                  <v-icon varia icon="mdi:mdi-chevron-down" />
                </template>
              </div>
            </v-col>
          </v-row>
          <div v-if="showAdditionalInfoFields">
            <v-row>
              <v-col cols="12" md="6">
                <AppTextField v-model="form.code" readonly label="Código" />
              </v-col>
              <v-col>
                <AppTextField v-model="form.inscricao_estadual" v-if="IsJuridic" label="Inscrição Estadual" />

                <AppTextField v-model="form.rg" v-if="!IsJuridic" label="Identidade" />
              </v-col>
            </v-row>
            <v-row class="d-flex align-center">
              <v-col>
                <AppTextField v-model="form.email" label="Email" />
              </v-col>
            </v-row>

            <v-row class="d-flex align-center">
              <v-col>
                <AppTextarea v-model="form.notes" label="Observações" />
              </v-col>
            </v-row>
          </div>
        </div>
        <v-row>
          <v-col class="py-0">
            <v-switch v-model="form.status" color="primary" label="Cadastro ativo" hide-details="auto" />
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <AppCancelBtn @click="dialog = false" />
        <template v-if="$acl.can('editSupplier')">
          <AppConfirmBtn @click="handleSave()"> Salvar </AppConfirmBtn>
        </template>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
const $acl = useAcl();

const $loading = useLoading();

const supplierForm = useSupplierStoreForm();

const { form, title, IsJuridic } = storeToRefs(supplierForm);

const dialog = ref(false);

const showAddressFields = ref(false);

const showAdditionalInfoFields = ref(false);

const emit = defineEmits(["store"]);

const open = async (id) => {
  dialog.value = true;

  supplierForm.$reset();

  if (id) {
    $loading.start();
    await supplierForm
      .show(id)
      .then((response) => {

        if (!form.value.main_address) {
          form.value.main_address = {
            type: "main",
            postal_code: null,
            country: null,
            state: null,
            city: null,
            address: null,
            number: null,
            complement: null,
            district: null,
            status: true,
          }
        }


        $loading.stop()
      })
      .catch((error) => $loading.stop());
  }
};

const handleSave = async () => {
  $loading.start();
  await supplierForm
    .save()
    .then((response) => {
      emit("store", response);
      dialog.value = false;
      $loading.stop();
    })
    .catch((error) => $loading.stop());
};

const handleCepInput = (address) => {
  form.value.main_address.city = address.city;
  form.value.main_address.state = address.state;

  if (address.address) {
    form.value.main_address.address = address.address;
  }

  if (address.district) {
    form.value.main_address.district = address.district;
  }

  if (address.complement) {
    form.value.main_address.complement = address.complement;
  }
};

const setCompanyData = (data) => {
  for (const key in data) {
    if (Object.hasOwnProperty.call(data, key)) {
      form.value[key] = data[key];
    }
  }
};

defineExpose({ open });
</script>

<style scoped></style>